import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CrmPersonSearches } from '../models/crm-person-searches';
import { delay, map, switchMap } from 'rxjs/operators';
import { DataWorkerService } from '../services/data-worker.service';
import { Person } from '../models/person';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CrmPersonSearchesEntityService extends BaseEntityService<CrmPersonSearches> {

  storageName = 'lastQuery';
  storageNameCache = 'clearCache';
  clearCache = environment.version;

  constructor(
    private dataWorkerService: DataWorkerService,
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'crmPersonSearch', plural: 'crmPersonSearches', url: 'crm-person-searches' },
      routerParamsService
    );
    this.nameId = 'crmPersonSearchId';
  }

  getCrmPersonSearch(organizationId: number) {
    const lastQuery = this.getToStorage(organizationId);
    return this.add({
      fields: [
        'id',
        'name',
        'document',
        'contractStartDate',
        'simpleDigitalStatus',
        'hrId',
        'orgGroupId',
        'eligibleForIncentive',
        'ownership',
      ],
      organization: {
        id: organizationId
      },
      changesSince: lastQuery?.queryStart ?? undefined,
    }).pipe(
      switchMap(response => {
        const { queryStart, results, deletions } = response;
        return this.dataWorkerService.saveItemsToWorker$(results, organizationId).pipe(
          map(() => {
            if (deletions && deletions.length > 0) {
              this.dataWorkerService.deleteMultipleIdsDatabase(deletions, organizationId);
            }
            this.setToStorage({ queryStart, organizationId });
            return results;
          })
        );
      }),
    );
  }

  getCrmPersonSearchConcierge(organizationId: number) {
    const lastQuery = this.getToStorage(organizationId);
    return this.add({
      fields: [
        'id',
        'person',
        'name',
        'birthdate',
        'hoProduct',
        'lastReferralRequestedAt',
        'lastFormSubmissionAt',
        'successPatientInteractions',
        'failurePatientInteractions',
        'interactionCriticality',
        'reasonsForInteraction',
        'document',
        'phone',
        'digitalStatus',
        'hrId',
        'orgGroupId',
        'city',
        'state',
        'pregnantAnswer',
        'eligibleForIncentive'
      ],
      organization: {
        id: organizationId
      },
      changesSince: lastQuery?.queryStart ?? undefined,
    }).pipe(
      switchMap(response => {
        const { queryStart, results, deletions } = response;
        return this.dataWorkerService.saveItemsToWorker$(results, organizationId).pipe(
          map(() => {
            if (deletions && deletions.length > 0) {
              this.dataWorkerService.deleteMultipleIdsDatabase(deletions, organizationId);
            }
            this.setToStorage({ queryStart, organizationId });
            return results;
          })
        );
      }),
    );
  }


  setToStorage(objToStorage: StorageLastQuery): void {
    let lastQuery: StorageLastQuery[] = [];
    try {
      lastQuery = JSON.parse(localStorage.getItem(this.storageName)) as StorageLastQuery[] || [];
    } catch (error) {
      lastQuery = [];
    }
    lastQuery = lastQuery.filter((lq) => lq.organizationId !== objToStorage.organizationId);
    lastQuery.push(objToStorage);
    localStorage.setItem(this.storageName, JSON.stringify(lastQuery));
  }

  getToStorage(organizationId: number): StorageLastQuery | null {
    let lastQuery: StorageLastQuery[] = [];
    try {
      lastQuery = JSON.parse(localStorage.getItem(this.storageName)) as StorageLastQuery[] || [];
    } catch (error) {
      return null;
    }
    return lastQuery.find((lq) => lq.organizationId === organizationId) || null;
  }

  clearLastQuery(): void {
    localStorage.setItem(this.storageName, '');
  }

  shouldClearCache(): Observable<boolean> {
    const clearCache = localStorage.getItem(this.storageNameCache) || '';
    if (clearCache !== this.clearCache) {
      this.clearLastQuery();
      localStorage.setItem(this.storageNameCache, this.clearCache);
      return this.clearObjectStore('crm-person-searches', 'crmPersonSearches').pipe(
        map(response => {
          return true;
        })
      );
    }
    return of(true);
  }

  clearObjectStore(dbName: string, storeName: string): Observable<void> {
    return new Observable<void>((observer) => {
      const request = indexedDB.open(dbName);

      request.onsuccess = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;

        // Se a tabela não existir, apenas resolve e finaliza
        if (!db.objectStoreNames.contains(storeName)) {
          console.warn(`⚠️ Object store "${ storeName }" does not exist. Skipping clear operation.`);
          observer.next(); // Emite um valor vazio para indicar que "deu certo"
          observer.complete(); // Finaliza normalmente
          return;
        }

        const transaction = db.transaction(storeName, 'readwrite');
        const objectStore = transaction.objectStore(storeName);
        const clearRequest = objectStore.clear();

        clearRequest.onsuccess = () => {
          console.log(`✅ Object store "${ storeName }" cleared successfully.`);
          observer.next();
          observer.complete();
        };

        clearRequest.onerror = (event) => {
          console.error(`❌ Error clearing object store "${ storeName }":`, event);
          observer.error(event);
        };
      };

      request.onerror = (event) => {
        console.error(`❌ Error opening IndexedDB "${ dbName }":`, event);
        observer.error(event);
      };
    });
  }
}

interface StorageLastQuery {
  queryStart: string,
  organizationId: number
}

export interface CrmPersonSearchesPreventionAdherence {
  id: number;
  name: string;
  document: string;
  contractStartDate: string;
  simpleDigitalStatus: string;
  hrId: number;
  orgGroupId: number;
}

export interface CrmPersonSearchesConcierge {
  id: number;
  person: Person;
  name: string;
  birthdate: string;
  hoProduct: string;
  lastReferralRequestedAt: string;
  lastFormSubmissionAt: string;
  successPatientInteractions: number;
  failurePatientInteractions: number;
  interactionCriticality: 'CRITICAL' | 'HIGH' | 'LOW';
  reasonsForInteraction: string[];
  reasonsForInteractionFormat: string;
  document: string;
  phone: string;
  digitalStatus: string;
  pregnantAnswer: boolean;
  hrId: number;
  orgGroupId: number;
  city: string;
  state: string;
  eligibleForIncentive: boolean;
}
