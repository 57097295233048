import { Injectable, signal, WritableSignal } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Organization } from '../../state/models/organization';
import { UrlService } from './url.service';
import { AmplitudeService } from './amplitude.service';
import { Cycles } from '../../state/models/cycles';
import { AuthStatements } from '../../state/models/auth-statements';
import { FeatureFlagService } from './feature-flag.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  private cycles: WritableSignal<Cycles> = signal(null);

  marOrgWhitelabel: Organization | undefined;
  isAdmin: boolean;
  isManyOrgs: boolean;
  authStatements: AuthStatements[];

  constructor(
    private featureFlagService: FeatureFlagService,
    private amplitudeService: AmplitudeService,
    private urlService: UrlService) {
  }

  get hasAdmin(): boolean {
    return this.isAdmin || false;
  }

  get hasManyOrgs(): boolean {
    return this.isManyOrgs || false;
  }

  get authStatementsList(): AuthStatements[] {
    return this.authStatements ?? [];
  }

  setCycles(cycles: Cycles) {
    this.cycles.set(cycles);
  }

  get cycle(): Cycles {
    return this.cycles();
  }

  setInfoPermissions(extras?: { hasAdmin?: boolean, hasManyOrgs?: boolean }, authStatements?: AuthStatements[]): void {
    const { hasAdmin = false, hasManyOrgs = false } = extras || {};
    this.isManyOrgs = hasManyOrgs;
    this.isAdmin = hasAdmin;
    this.authStatements = authStatements;
  }

  clearOrganization() {
    this.marOrgWhitelabel = undefined;
    this.cycles.set(undefined);
  }

  setOrganization(organization?: Organization): boolean {
    if (organization) {
      this.marOrgWhitelabel = organization;
      this.getAmplitudeInfo(organization);
    } else {
      this.marOrgWhitelabel = DEFAULT_ORGANIZATION;
    }
    this.amplitudeService.setIdentify();
    return true;
  }

  getOrganization(): Organization | undefined {
    return this.marOrgWhitelabel || DEFAULT_ORGANIZATION;
  }

  public getTitleDefaultPage(title?: string): string {
    if (window.innerWidth < 600) { // Disable for mobile
      return title;
    }
    if (this.getOrganization().type === 'HEALTH_OPERATOR') {
      return `Painel da operadora - ${ this.getOrganization().name }`;
    }

    if (['HUMAN_RESOURCES', 'GROUP'].includes(this.getOrganization().type)) {
      return `Painel da empresa - ${ this.getOrganization().name }`;
    }

    return title;
  }

  get isHP(): boolean {
    if (window.innerWidth < 600) { // Disable for mobile
      return false;
    }
    return this.getOrganization().type === 'HEALTH_OPERATOR';
  }

  get isHPMobile(): boolean {
    if (window.innerWidth > 600) { // Disable for mobile
      return false;
    }
    return this.getOrganization().type === 'HEALTH_OPERATOR';
  }

  public mobileTitle(title: string): string {
    if (window.innerWidth > 600) { // Disable for mobile
      return title;
    }
    if (this.getOrganization().type === 'HEALTH_OPERATOR') {
      return `Painel da operadora`;
    }

    if (['HUMAN_RESOURCES', 'GROUP'].includes(this.getOrganization().type)) {
      return `Painel da empresa`;
    }
  }

  getAmplitudeInfo(organization: Organization) {
    const { parent, type, id, name } = organization;
    this.amplitudeService.addEdentifyEvent('org_id', id?.toString());
    this.amplitudeService.addEdentifyEvent('org_type', type);
    this.amplitudeService.addEdentifyEvent('org_name', name);
    if (parent) {
      const { type: pType, id: pId, name: pName } = parent;
      this.amplitudeService.addEdentifyEvent('parent_org_id', pId?.toString());
      this.amplitudeService.addEdentifyEvent('parent_org_type', pType);
      this.amplitudeService.addEdentifyEvent('parent_org_name', pName);
    }
  }

  getDefaultUrl(organization?: Organization): string {
    if (!organization) {
      organization = this.getOrganization();
    }
    if (['HUMAN_RESOURCES', 'GROUP'].includes(organization.type)) {
      return this.urlService.organizationsRhHome(organization.id);
    } else if (['HEALTH_OPERATOR', 'BROKER'].includes(organization.type)) {
      const isDoctor = this.authStatements?.find(as =>
        as?.role?.name === 'DOCTOR' && as?.resource?.organization?.id === organization.id
      );

      if (isDoctor) {
        return this.urlService.organizationsHomeDoctor(organization.id);
      }
      return this.urlService.organizationsHome(organization.id);
    } else if (organization.type === 'SALESPERSON') {
      return this.urlService.organizationsSalesBonus(organization.id);
    }
    return this.urlService.organizationsHome(organization.id);
  }

  private isDevEnvironment(): boolean {
    return environment.env === 'dev';
  }
}

const DEFAULT_ORGANIZATION: Organization = {
  nameUrlSafe: 'mar',
  name: 'Gestor | Mar Saúde',
  logo: 'https://mar-static.s3.us-east-2.amazonaws.com/organization-logo/mar.png',
  favicon: 'https://mar-static.s3.us-east-2.amazonaws.com/organization-favicon/mar.ico'
};
