import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'nl2br',
  standalone: false
})
export class Nl2brPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }

  transform(value: string): SafeHtml {
    if (!value) return '';
    // Substitui todas as quebras de linha por <br/>
    const formatted = value.replace(/(\r\n|\n|\r)/g, '<br/>');
    return this.sanitizer.bypassSecurityTrustHtml(formatted);
  }
}
