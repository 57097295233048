import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { JwtAuthService } from '../services/auth/jwt-auth.service';
import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthStatementsEntityService } from '../../state/entity-services/auth-statements-entity.service';
import { AuthStatements } from '../../state/models/auth-statements';
import { OrganizationEntityService } from '../../state/entity-services/organization-entity.service';
import { OrganizationService } from '../services/organization.service';
import { AmplitudeService } from '../services/amplitude.service';
import { Person } from '../../state/models/person';
import { DateService } from '../services/date.service';
import { FeatureFlagService } from '../services/feature-flag.service';
import { CrmPersonSearchesEntityService } from '../../state/entity-services/crm-person-searches-entity.service';

@Injectable()
export class OrganizationListGuard implements CanActivate {
  constructor(
    private crmPersonSearchesEntityService: CrmPersonSearchesEntityService,
    private featureFlagService: FeatureFlagService,
    private dateService: DateService,
    private jwtAuthService: JwtAuthService,
    private amplitudeService: AmplitudeService,
    private organizationService: OrganizationService,
    private organizationEntityService: OrganizationEntityService,
    private authStatementsEntityService: AuthStatementsEntityService,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (!this.jwtAuthService.isLoggedIn) {
      this.redirectToLogin(state.url);
      return of(false);
    }
    return forkJoin([
      this.authStatementsEntityService.getWithQuery({
        principalPersonIds: this.jwtAuthService.getUser().id,
      }),
      this.crmPersonSearchesEntityService.shouldClearCache()
    ]).pipe(
      switchMap(response => {
        const [authStatements] = response;
        return this.handleAuthStatements(authStatements);
      })
    );
  }

  private redirectToLogin(returnUrl: string): void {
    this.router.navigate(['/sessions/login'], { queryParams: { return: returnUrl } });
  }

  private handleAuthStatements(authStatements: AuthStatements[]): Observable<boolean> {
    this.organizationService.setOrganization(null);
    const user = this.jwtAuthService.getUser();

    this.trackUserEvents(user, authStatements);

    if (this.isAdmin(authStatements)) {
      this.organizationService.setInfoPermissions({ hasAdmin: true }, authStatements);
      return of(true);
    }

    const orgIds = this.getUniqueOrgIds(authStatements);

    if (orgIds.length > 1) {
      this.organizationService.setInfoPermissions({ hasManyOrgs: true }, authStatements);
      return of(true);
    }
    return this.loadOrganization(orgIds[0], authStatements);
  }

  private isAdmin(authStatements: AuthStatements[]): boolean {
    return authStatements.some(as => as.role.name === 'ADMIN');
  }

  private getUniqueOrgIds(authStatements: AuthStatements[]): number[] {
    return authStatements
      .filter(as => ['MANAGER', 'DOCTOR'].includes(as.role.name))
      .map(as => as?.resource?.organization?.id)
      .filter((id, index, self) => id && self.indexOf(id) === index);
  }

  private trackUserEvents(user: Person, authStatements: AuthStatements[]): void {
    this.amplitudeService.setUserId(user.id.toString());

    const { birthdate, biologicalSex } = user.personProperties;
    this.amplitudeService.addEdentifyEvent('biological_sex', biologicalSex);
    this.amplitudeService.addEdentifyEvent('age', this.dateService.diffByYears(birthdate).toString());

    authStatements.forEach(as => {
      this.amplitudeService.addEdentifyEvent('user_type', as.role.name);
    });
  }

  private loadOrganization(organizationId: number, authStatements: AuthStatements[]): Observable<boolean> {
    if (!organizationId) {
      return of(false);
    }

    this.organizationService.setInfoPermissions({ hasManyOrgs: false, hasAdmin: false }, authStatements);
    return this.organizationEntityService.getById(organizationId).pipe(
      switchMap(organization =>
        this.featureFlagService.getFeatureFlagSearchesByOrgId(organization.id).pipe(
          map(() => organization)
        )
      ),
      map(organization => {
        this.organizationService.setOrganization(organization);
        this.router.navigateByUrl(this.organizationService.getDefaultUrl());
        return true;
      })
    );
  }
}
