import { Pipe, PipeTransform } from '@angular/core';
import { NumeralService } from '../services/numeral.service';

@Pipe({
  name: 'numeralFormat',
  standalone: false
})
export class NumeralPipe implements PipeTransform {
  constructor(private numeralService: NumeralService) {
  }

  transform(value: any, format = '0,0.[0000]'): string {
    if (!value) {
      if (format === '%') {
        return '0%';
      }
      return value;
    }
    value = parseFloat(value);

    return this.numeralService.getNumeral(value, format);
  }
}
