import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { JwtAuthService } from '../services/auth/jwt-auth.service';
import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganizationService } from '../services/organization.service';
import { OrganizationEntityService } from '../../state/entity-services/organization-entity.service';
import { FaviconService } from '../services/favicon.service';
import { AuthStatementsEntityService } from '../../state/entity-services/auth-statements-entity.service';
import { CyclesEntityService } from '../../state/entity-services/cycles-entity.service';
import { FeatureFlagService } from '../services/feature-flag.service';
import { AuthStatements } from '../../state/models/auth-statements';
import { CrmPersonSearchesEntityService } from '../../state/entity-services/crm-person-searches-entity.service';

@Injectable()
export class OrganizationInternalGuard implements CanActivate {
  constructor(
    private crmPersonSearchesEntityService: CrmPersonSearchesEntityService,
    private featureFlagService: FeatureFlagService,
    private cyclesEntityService: CyclesEntityService,
    private authStatementsEntityService: AuthStatementsEntityService,
    private faviconService: FaviconService,
    private organizationService: OrganizationService,
    private organizationEntityService: OrganizationEntityService,
    private router: Router, private jwtAuthService: JwtAuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (!this.jwtAuthService.isLoggedIn) {
      this.router.navigate(['/sessions/login'], {
        queryParams: {
          return: state.url,
        },
      });
      return of(false);
    }

    const organizationId = this.findOrganization(route);
    if (!organizationId) {
      return of(true);
    }

    return forkJoin(
      [
        this.organizationEntityService.getById(organizationId),
        this.cyclesEntityService.getCurrentCycleId(organizationId),
        this.featureFlagService.getFeatureFlagSearchesByOrgId(parseInt(organizationId, 10)),
        this.crmPersonSearchesEntityService.shouldClearCache(),
      ]
    ).pipe(
      map(response => {
        const [organization, cycle] = response;
        this.organizationService.setOrganization(organization);
        this.organizationService.setCycles(cycle);
        this.faviconService.changeFavicon(organization.favicon);
        return true;
      }),
      switchMap(() => {
        return this.authStatementsEntityService.getWithQuery({
          principalPersonIds: this.jwtAuthService.getUser().id,
        }).pipe(
          map(authStatements => {
            if (authStatements.some(as => as.role.name === 'ADMIN')) {
              this.organizationService.setInfoPermissions({ hasAdmin: true }, authStatements);
              return true;
            }

            const orgIds = this.getUniqueOrgIds(authStatements);

            if (orgIds.length > 1) {
              this.organizationService.setInfoPermissions({ hasManyOrgs: true }, authStatements);
              return true;
            }

            this.organizationService.setInfoPermissions({ hasManyOrgs: false, hasAdmin: false }, authStatements);
            return true;
          })
        );
      })
    );
  }

  private getUniqueOrgIds(authStatements: AuthStatements[]): number[] {
    return authStatements
      .filter(as => ['MANAGER', 'DOCTOR'].includes(as.role.name))
      .map(as => as?.resource?.organization?.id)
      .filter((id, index, self) => id && self.indexOf(id) === index);
  }

  private findOrganization(route: ActivatedRouteSnapshot): string | undefined {
    return route?.params['organizationId'] || route?.children && this.findOrganization(route.children[0]);
  }
}


