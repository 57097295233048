{
  "name": "painel",
  "version": "1.16.18",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port=7200 --open --host 0.0.0.0 --disable-host-check ",
    "build": "ng build --configuration=production --aot=true --optimization=true",
    "build:stag": "ng build --configuration=stag --aot=true --optimization=true",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "sonar": "sonar-scanner",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org mar-saude --project patient ./dist/patient && sentry-cli sourcemaps upload --org mar-saude --project patient ./dist/patient",
    "changelog-version": "node generate-changelog-update-version.js",
    "commit-version": "git add . && git commit -m'Version name: '$(grep -m1 version package.json | awk -F: '{ print $2 }' | sed 's/[\", ]//g')''",
    "update-angular": "ng update @angular/core @angular/cli @angular/cdk @angular-devkit/build-angular @angular/compiler-cli @angular/material @angular/material-experimental",
    "release": "npm run changelog-version && npm run commit-version && git push && git checkout main && git pull  && git merge --no-ff dev && git push && git checkout -"
  },
  "private": true,
  "dependencies": {
    "@amplitude/analytics-browser": "^2.9.3",
    "@angular/animations": "^19.0.5",
    "@angular/cdk": "^19.0.4",
    "@angular/common": "^19.0.5",
    "@angular/compiler": "^19.0.5",
    "@angular/core": "^19.0.5",
    "@angular/forms": "^19.0.5",
    "@angular/material": "^19.0.4",
    "@angular/material-experimental": "^19.0.4",
    "@angular/material-moment-adapter": "^19.0.4",
    "@angular/platform-browser": "^19.0.5",
    "@angular/platform-browser-dynamic": "^19.0.5",
    "@angular/router": "^19.0.5",
    "@ngx-formly/core": "^6.3.0",
    "@ngx-formly/material": "^6.3.0",
    "@sentry/angular": "^8.47.0",
    "@sentry/cli": "^2.40.0",
    "@swimlane/ngx-charts": "^22.0.0-alpha.0",
    "ag-grid-angular": "^32.3.3",
    "ag-grid-charts-enterprise": "^32.3.3",
    "ag-grid-community": "^32.3.3",
    "canvg": "^4.0.3",
    "chart.js": "^4.4.8",
    "chartjs-plugin-datalabels": "^2.2.0",
    "core-js": "^3.40.0",
    "d3": "^7.9.0",
    "dayjs": "^1.11.13",
    "html2canvas": "^1.4.1",
    "html2pdf.js": "^0.10.3",
    "iframe-resizer": "^4.3.11",
    "jspdf": "^3.0.1",
    "lodash": "^4.17.21",
    "ng2-charts": "^8.0.0",
    "ng2-currency-mask": "^13.0.3",
    "ngx-file-drop": "^16.0.0",
    "ngx-mask": "^17.0.7",
    "numeral": "^2.0.6",
    "rxjs": "~7.8.0",
    "tslib": "^2.6.3",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.0.6",
    "@angular/cli": "^19.0.6",
    "@angular/compiler-cli": "^19.0.5",
    "@types/jasmine": "~5.1.5",
    "@types/numeral": "^2.0.5",
    "jasmine-core": "~5.6.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "semver": "^7.6.0",
    "sonar-scanner": "^3.1.0",
    "typescript": "~5.6.3"
  }
}